@import "/src/fonts.scss";
@import "/node_modules/purple-lib/styles/purple-bootstrap.scss";
@import "/node_modules/purple-lib/styles/variable.scss";
@import "/src/theme/variable.scss";
@import "/src/theme/typography.scss";

html {
    font-family: "Inter", sans-serif !important;
    background-color: $background-color;
    overflow-x: hidden;
    overflow-y: hidden;
}

body{
    height: auto;
}

app-dashboard {
    width: 100%;
}

nz-layout {
    min-height: 100vh;
}

//Scrollbar
::-webkit-scrollbar {
    width: 10px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 20px;
    -webkit-border-radius: 20px;
    background: $scrollbar-color;
}

::-webkit-scrollbar-thumb:hover {
    background: $scrollbar-hover-color;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $scrollbar-background-shadow-color;
    background-color: $scrollbar-background-color;
}
.ant-tabs-content-holder {
    overflow: scroll !important;
  }