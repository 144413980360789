//Primary color
$primary-color: #3a6959;
$secondary-color: #dedffd;
$tertiary-color: #ffe493;

//typography
$title-color: #a0a0a0;
$subtitle-color: #5c5c5c;
$text-color: #1b1f24;
//$background-color: #fdfdfd;
$background-color: #fff;

//Footer
$footer-background-color-top: #000000;
$footer-background-color-bottom: #1e1d1d;

//Header
$header-background-color: #000000;
$header-button-selected-text-color: #000000;
$header-button-selected-background-color: #dedffd;

//PageTitle
$page-title-color: #1b1f24;
$page-subtitle-color: #475467;

//SideMenu
$side-menu-background: #1c1a1b;
$side-menu-not-selected-text-color: #fff;
$side-menu-selected-text-color: #1c1a1b;
$side-menu-selected-background-color: #dedffd;
$side-main-menu-text-color: #a9afb8;

//scrollbar
$scrollbar-background-color: #f7f7f7;
$scrollbar-color: rgba($primary-color, 0.6);
$scrollbar-hover-color: $primary-color;
$scrollbar-background-shadow-color: #f7f7f7;

//Modal
$title-modal-color: #000;

:root {
    --purple-lib-primary-color: #3a6959;
    --purple-lib-font-size: 14px;
    --purple-lib-font-family: "Inter", sans-serif !important;
    --purple-lib-body-background: #fdfdfd;
    --purple-lib-disabled-color: fade(#000, 55%);

    //PageTitle
    --purple-lib-page-title-color: #1b1f24;
    --purple-lib-page-subtitle-color: #475467;

    //SideMenu
    --purple-lib-side-menu-background: #1c1a1b;
    --purple-lib-side-menu-not-selected-text-color: #fff;
    --purple-lib-side-menu-selected-text-color: #1c1a1b;
    --purple-lib-side-menu-selected-background-color: #dedffd;
    --purple-lib-side-main-menu-text-color: #a9afb8;
}
