/* libre-franklin-100 - latin */
@font-face {
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 100;
  src: url('assets/fonts/libre/libre-franklin-v7-latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/libre/libre-franklin-v7-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/libre/libre-franklin-v7-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/libre/libre-franklin-v7-latin-100.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/libre/libre-franklin-v7-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/libre/libre-franklin-v7-latin-100.svg#LibreFranklin') format('svg'); /* Legacy iOS */
}
/* libre-franklin-200 - latin */
@font-face {
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 200;
  src: url('assets/fonts/libre/libre-franklin-v7-latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/libre/libre-franklin-v7-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/libre/libre-franklin-v7-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/libre/libre-franklin-v7-latin-200.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/libre/libre-franklin-v7-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/libre/libre-franklin-v7-latin-200.svg#LibreFranklin') format('svg'); /* Legacy iOS */
}
/* libre-franklin-300 - latin */
@font-face {
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/libre/libre-franklin-v7-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/libre/libre-franklin-v7-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/libre/libre-franklin-v7-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/libre/libre-franklin-v7-latin-300.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/libre/libre-franklin-v7-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/libre/libre-franklin-v7-latin-300.svg#LibreFranklin') format('svg'); /* Legacy iOS */
}
/* libre-franklin-regular - latin */
@font-face {
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/libre/libre-franklin-v7-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/libre/libre-franklin-v7-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/libre/libre-franklin-v7-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/libre/libre-franklin-v7-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/libre/libre-franklin-v7-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/libre/libre-franklin-v7-latin-regular.svg#LibreFranklin') format('svg'); /* Legacy iOS */
}
/* libre-franklin-500 - latin */
@font-face {
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/libre/libre-franklin-v7-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/libre/libre-franklin-v7-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/libre/libre-franklin-v7-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/libre/libre-franklin-v7-latin-500.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/libre/libre-franklin-v7-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/libre/libre-franklin-v7-latin-500.svg#LibreFranklin') format('svg'); /* Legacy iOS */
}
/* libre-franklin-600 - latin */
@font-face {
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 600;
  src: url('assets/fonts/libre/libre-franklin-v7-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/libre/libre-franklin-v7-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/libre/libre-franklin-v7-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/libre/libre-franklin-v7-latin-600.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/libre/libre-franklin-v7-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/libre/libre-franklin-v7-latin-600.svg#LibreFranklin') format('svg'); /* Legacy iOS */
}
/* libre-franklin-700 - latin */
@font-face {
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/libre/libre-franklin-v7-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/libre/libre-franklin-v7-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/libre/libre-franklin-v7-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/libre/libre-franklin-v7-latin-700.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/libre/libre-franklin-v7-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/libre/libre-franklin-v7-latin-700.svg#LibreFranklin') format('svg'); /* Legacy iOS */
}
/* libre-franklin-800 - latin */
@font-face {
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 800;
  src: url('assets/fonts/libre/libre-franklin-v7-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/libre/libre-franklin-v7-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/libre/libre-franklin-v7-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/libre/libre-franklin-v7-latin-800.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/libre/libre-franklin-v7-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/libre/libre-franklin-v7-latin-800.svg#LibreFranklin') format('svg'); /* Legacy iOS */
}
/* libre-franklin-900 - latin */
@font-face {
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 900;
  src: url('assets/fonts/libre/libre-franklin-v7-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/libre/libre-franklin-v7-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/libre/libre-franklin-v7-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/libre/libre-franklin-v7-latin-900.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/libre/libre-franklin-v7-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/libre/libre-franklin-v7-latin-900.svg#LibreFranklin') format('svg'); /* Legacy iOS */
}

/* anton-regular - latin-ext_latin */
@font-face {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/anton/anton-v15-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/anton/anton-v15-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/anton/anton-v15-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/anton/anton-v15-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/anton/anton-v15-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/anton/anton-v15-latin-ext_latin-regular.svg#Anton') format('svg'); /* Legacy iOS */
}


/* roboto-100 - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('assets/fonts/roboto/roboto-v29-latin-ext_latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-100.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-100.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-100italic - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: url('assets/fonts/roboto/roboto-v29-latin-ext_latin-100italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-100italic.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-100italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300 - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/roboto/roboto-v29-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300italic - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url('assets/fonts/roboto/roboto-v29-latin-ext_latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-300italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/roboto/roboto-v29-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-italic - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('assets/fonts/roboto/roboto-v29-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/roboto/roboto-v29-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500italic - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url('assets/fonts/roboto/roboto-v29-latin-ext_latin-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-500italic.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-500italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/roboto/roboto-v29-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700italic - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('assets/fonts/roboto/roboto-v29-latin-ext_latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-700italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-900 - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('assets/fonts/roboto/roboto-v29-latin-ext_latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-900.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-900italic - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: url('assets/fonts/roboto/roboto-v29-latin-ext_latin-900italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-900italic.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/roboto/roboto-v29-latin-ext_latin-900italic.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* montserrat-100 - latin-ext_latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  src: url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-100.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-100.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-200 - latin-ext_latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-200.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-200.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-300 - latin-ext_latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-300.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-regular - latin-ext_latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-500 - latin-ext_latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin-ext_latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin-ext_latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-800 - latin-ext_latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-800.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-800.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-900 - latin-ext_latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-900.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/montserrat/montserrat-v21-latin-ext_latin-900.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* inter-100 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  src: url('assets/fonts/inter/inter-v12-latin_latin-ext-100.eot'); /* IE9 Compat Modes */
  src: url('assets/fonts/inter/inter-v12-latin_latin-ext-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-100.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-100.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-200 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src: url('assets/fonts/inter/inter-v12-latin_latin-ext-200.eot'); /* IE9 Compat Modes */
  src: url('assets/fonts/inter/inter-v12-latin_latin-ext-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-200.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-200.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-300 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/inter/inter-v12-latin_latin-ext-300.eot'); /* IE9 Compat Modes */
  src: url('assets/fonts/inter/inter-v12-latin_latin-ext-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-300.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-300.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-regular - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/inter/inter-v12-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
  src: url('assets/fonts/inter/inter-v12-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-regular.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-regular.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-500 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/inter/inter-v12-latin_latin-ext-500.eot'); /* IE9 Compat Modes */
  src: url('assets/fonts/inter/inter-v12-latin_latin-ext-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-500.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-500.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-600 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('assets/fonts/inter/inter-v12-latin_latin-ext-600.eot'); /* IE9 Compat Modes */
  src: url('assets/fonts/inter/inter-v12-latin_latin-ext-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-600.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-600.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-700 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/inter/inter-v12-latin_latin-ext-700.eot'); /* IE9 Compat Modes */
  src: url('assets/fonts/inter/inter-v12-latin_latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-700.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-700.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-800 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url('assets/fonts/inter/inter-v12-latin_latin-ext-800.eot'); /* IE9 Compat Modes */
  src: url('assets/fonts/inter/inter-v12-latin_latin-ext-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-800.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-800.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-900 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url('assets/fonts/inter/inter-v12-latin_latin-ext-900.eot'); /* IE9 Compat Modes */
  src: url('assets/fonts/inter/inter-v12-latin_latin-ext-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-900.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/inter/inter-v12-latin_latin-ext-900.svg#Inter') format('svg'); /* Legacy iOS */
}